import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SynchroFolder } from '../../models/synchroFolder.model';
import { MachinesService } from '../machines/machines.service';
import { Machine } from '../../models/machine.model';
import { Synchro } from '../../models/synchro.model';

const BACKEND_URL = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class SynchroService {
  private synchroFoldersUpdated = new Subject<SynchroFolder[]>();

  constructor(private http: HttpClient, private machineService: MachinesService) {}

  // AVEC FILE MANAGER
  getMediasByMachine(machineId) {
    return this.machineService.getMachine(machineId);
  }

  // récupérer les dates de synchro
  getUpdateDate(machineId) {
    console.log('getupdatedates:', machineId);
    const url = BACKEND_URL + '/synchro/getupdatedate/' + machineId;
    return this.http.get<Synchro>(url);
  }

  updateSynchroDueDate(machines: Machine[], synchroDueDate) {
    console.log('updatesynchrodates');
    const data = {
      synchroDueDate: synchroDueDate,
      machines: machines,
    };
    console.log(data);
    this.http.post(BACKEND_URL + '/synchro/updatedates/', data).subscribe((result) => {
      return result;
    });
  }
  // FIN AVEC FILE MANAGER

  // Renvoie les dossiers et les fichiers correspondants pour la machine demandée
  getSynchroFtpByMachineId(machineId, synchroFolders: SynchroFolder[]): Promise<SynchroFolder[]> {
    let promises: Promise<SynchroFolder>[] = synchroFolders.map(async (synchroFolder) => {
      synchroFolder.files = await this.getFileListByMachineFolder(machineId, synchroFolder.folderName).toPromise();
      return new Promise<SynchroFolder>((res, rej) => res(synchroFolder));
    });
    return Promise.all(promises);
  }

  // RECUPERER LA LISTE DES FICHIERS D'UN DOSSIER FTP
  getFileListByMachineFolder(machineId, dossier?): Observable<[]> {
    if (!dossier) {
      dossier = '/THEMES';
    } else {
      dossier = '/THEMES/' + dossier;
    }
    const url = BACKEND_URL + '/synchro/' + machineId + '/?folder=' + dossier;
    const files = this.http.get<[]>(url);
    console.log('files:', files);
    return files;
  }

  // UPLOADER UN NOUVEAU FICHIER SUR LE SERVEUR FTP
  uploadNewFile(machineId: string, file: File, dossier?: string) {
    let url: string;
    const postData = new FormData();
    postData.append('file', file);

    // dans le cas du dossier IMAGESVEILLE, on ne supprime pas le contenu du dossier
    if (dossier != 'THEMES/IMAGESVEILLE') {
      postData.append('emptyFolderBeforeUpload', 'True');
    } else {
      postData.append('emptyFolderBeforeUpload', 'False');
    }

    console.log('postdata:', postData);

    //Dans le cas d'un dossier de video, on post sur l'url des vidéos
    if (dossier.indexOf('VIDEO') >= 0) {
      url = BACKEND_URL + '/synchro/VIDEO/' + machineId + '/?folder=' + dossier;
    } else {
      url = BACKEND_URL + '/synchro/' + machineId + '/?folder=' + dossier;
    }

    console.log(url);
    return this.http.post<{ success: string; rejects: [{ reject: File; reason: string }] }>(url, postData);
  }

  // SUPPRIMER UN FICHIER DU SERVEUR FTP
  deleteFile(machineId, path) {
    const url = BACKEND_URL + '/synchro/' + machineId + '/?file=' + path;
    return this.http.delete(url);
  }
}
