<div>
  <span>{{ this.data.title }}</span>
  <app-page-action label="Ajouter" icon="add_box" (click)="chooseFile()"></app-page-action>
</div>
<div mat-dialog-content style="width: 100%">
  <app-file-manager #fileManager></app-file-manager>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Annuler</button>
  <button mat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>Valider</button>
</div>
