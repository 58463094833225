import { Component, OnInit, HostBinding, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss'],
})
export class ProgressDialogComponent implements OnInit {
  title: string;
  text: string;
  details: string;
  description: string;
  confirmationButtonColor = 'warn';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ProgressDialogComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    const { title, text } = this.data;
    this.title = title;
    this.text = text;
  }
}
