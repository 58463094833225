import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { RecursiveTemplateAstVisitor } from '@angular/compiler';

const BACKEND_URL = environment.serverUrl;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.startsWith(BACKEND_URL)) {
      const authToken = this.authService.getToken();
      const authRequest = req.clone({
        headers: req.headers.set('auth', 'Baerer ' + authToken),
      });
      return next.handle(authRequest);
    } else {
      return next.handle(req);
    }
  }
}
