import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthData } from './auth-data';
import { User } from 'src/app/models/user.model';

const BACKEND_URL = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticated = false;
  private authenticatedUser: User;
  private token: string;
  private authStatusListener = new Subject<User>();
  private tokenTimer: any;

  constructor(private http: HttpClient, private router: Router) {}

  login(email: string, password: string, destinationUrl?) {
    const authData: AuthData = { email, password };
    this.http.post<{ token: string; expiresIn: number; user: User }>(BACKEND_URL + '/auth/login', authData).subscribe(
      (response) => {
        const token = response.token;
        this.token = token;
        if (token) {
          const expiresInDuration = response.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.authenticated = true;
          this.authenticatedUser = response.user;
          this.authStatusListener.next(this.authenticatedUser);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          this.saveAuthData(token, expirationDate, this.getUserId());
          this.router.navigate([destinationUrl]);
        }
      },
      (error) => {
        console.log(error);
        this.authStatusListener.next(null);
      },
    );
  }

  activate(temporaryToken: string) {
    return this.http.post<{ message: string }>(BACKEND_URL + '/auth/activate', { temporaryToken });
  }

  resetPassword(temporaryToken: string, password: string) {
    return this.http.post<{ message: string }>(BACKEND_URL + '/auth/reset-password', { temporaryToken, newPassword: password });
  }

  private setAuthTimer(expiresInDuration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, expiresInDuration * 1000);
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.authenticated = true;
      this.setAuthTimer(expiresIn / 1000);

      // Chargement de l'utilisateur
      this.http.get<User>(BACKEND_URL + '/user/' + authInformation.userId).subscribe((user) => {
        this.authenticatedUser = user;
        this.authStatusListener.next(this.authenticatedUser);
      });
    }
  }

  logout() {
    this.token = null;
    this.authenticated = false;
    this.authenticatedUser = null;
    this.authStatusListener.next(null);
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/login']);
  }

  private saveAuthData(token: string, expirationDate: Date, userId: number) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('userId', '' + userId);
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userId');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const userId = +localStorage.getItem('userId');
    if (!token || !expirationDate) {
      return;
    }
    return {
      token,
      expirationDate: new Date(expirationDate),
      userId,
    };
  }

  getToken() {
    return this.token;
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  getAuthenticatedUser(): User {
    return this.authenticatedUser;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getUserId() {
    return this.authenticatedUser.id;
  }
}
