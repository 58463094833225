import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { latLng, Map, MapOptions, tileLayer, ZoomAnimEvent } from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: `map.component.html`,
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  @Output() map$: EventEmitter<Map> = new EventEmitter();
  @Output() zoom$: EventEmitter<number> = new EventEmitter();
  @Input() options: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        opacity: 0.7,
        detectRetina: true,
        attribution: '&copy; OpenStreetMap contributors',
      }),
    ],
    zoom: 5,
    center: latLng(46.34, 4.59),
  };
  public map: Map;
  public zoom: number;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {
    this.map.clearAllEventListeners();
    this.map.remove();
  }

  onMapReady(map: Map) {
    this.map = map;
    this.map$.emit(map);
    this.zoom = map.getZoom();
    this.zoom$.emit(this.zoom);
  }

  onMapZoomEnd(e: ZoomAnimEvent) {
    this.zoom = e.target.getZoom();
    this.zoom$.emit(this.zoom);
  }
}
