import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileManagerService } from '../file-manager.service';

@Component({
  selector: 'app-file-manager-dialog',
  templateUrl: './file-manager-dialog.component.html',
  styleUrls: ['./file-manager-dialog.component.scss'],
})
export class FileManagerDialogComponent implements OnInit {
  @ViewChild('fileManager')
  fileManager;
  chosenFiles: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fileManagerService: FileManagerService) {}

  ngOnInit() {
    // on prévient le service si la sélection multiple est possible
    this.fileManagerService.multiFiles = this.data.multiFiles;
    this.fileManagerService.filesType = this.data.filesType;
  }

  chooseFile() {
    this.fileManager.filePicker.nativeElement.click();
  }
}
