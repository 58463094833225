import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MapComponent } from './components/map/map.component';
import { CancelActionComponent } from './components/page-actions/cancel-action/cancel-action.component';
import { PageActionComponent } from './components/page-actions/page-action/page-action.component';
import { PageActionsComponent } from './components/page-actions/page-actions.component';
import { ProgressDialogComponent } from './components/progress-dialog/progress-dialog.component';
import { ErrorComponent } from './error/components/dialog/error.component';
import { ErrorSnackComponent } from './error/components/snack/error.component';
import { ErrorInterceptor } from './error/error-interceptor';
import { MaterialModule } from './material.module';
import { AuthInterceptor } from './security/auth-interceptor';
import { AuthGuard } from './security/auth.guard';
import { HasRoleDirective } from './security/hasRole.directive';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { IsKimpDirective } from './security/isKimp.directive';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { FileManagerDialogComponent } from './components/file-manager/file-manager-dialog/file-manager-dialog.component';
import { DndDirective } from '../dnd.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    HttpClientModule,
    PortalModule,
    LeafletModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
  ],
  declarations: [
    PageActionsComponent,
    PageActionComponent,
    MapComponent,
    HasRoleDirective,
    // IsKimpDirective,
    ConfirmationDialogComponent,
    ProgressDialogComponent,
    CancelActionComponent,
    ErrorComponent,
    ErrorSnackComponent,
    ImageSliderComponent,
    FileManagerComponent,
    FileManagerDialogComponent,
    DndDirective,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    PageActionsComponent,
    PageActionComponent,
    MapComponent,
    MaterialModule,
    FlexLayoutModule,
    PortalModule,
    LeafletModule,
    HasRoleDirective,
    // IsKimpDirective,
    ConfirmationDialogComponent,
    ProgressDialogComponent,
    CancelActionComponent,
    ErrorComponent,
    ErrorSnackComponent,
    ImageSliderComponent,
    ReactiveFormsModule,
    FormsModule,
    FileManagerComponent,
    FileManagerDialogComponent,
  ],
  entryComponents: [ErrorSnackComponent, ErrorComponent],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class SharedModule {}
