import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cancel-action',
  templateUrl: './cancel-action.component.html',
})
export class CancelActionComponent {
  @Input() color = 'warn';
  @Input() icon = 'cancel';
  @Input() label = 'Annuler';

  constructor(private location: Location) {}

  cancel() {
    this.location.back();
  }
}
