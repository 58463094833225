import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/security/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule), canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule) },
  { path: 'activation', loadChildren: () => import('./auth/activation/activation.module').then((m) => m.ActivationModule) },
  { path: 'reset-password', loadChildren: () => import('./auth/password/password.module').then((m) => m.PasswordModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
