import { Component, OnInit, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-page-action',
  templateUrl: './page-action.component.html',
  styleUrls: ['./page-action.component.scss'],
})
export class PageActionComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result) => result.matches));

  @Input() color = 'primary';
  @Input() disabled = false;
  @Input() icon: string;
  @Input() label: string;
  @Input() tooltip: string;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {}
}
