import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Machine } from 'src/app/models/machine.model';
import { environment } from 'src/environments/environment';
import { Modele } from 'src/app/models/modele.model';
import { Observable } from 'rxjs';

const BACKEND_URL = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class MachinesService {
  constructor(private http: HttpClient) {}

  getMachine(machineId) {
    return this.http.get<Machine>(BACKEND_URL + '/machine/' + machineId);
  }

  loadMachines() {
    return this.http.get<Machine[]>(BACKEND_URL + '/machine');
  }

  createMachine(machine): Observable<Machine> {
    return this.http.post<Machine>(BACKEND_URL + '/machine', machine);
  }

  updateMachine(machine): Observable<Machine> {
    console.log('update machine:', machine);
    return this.http.put<Machine>(BACKEND_URL + '/machine/' + machine.id, machine);
  }

  loadModeles() {
    return this.http.get<Modele[]>(BACKEND_URL + '/machine/modeles/all');
  }
}
