<div
  class="container"
  fxFlex
  fxLayout="row"
  fxLayoutAlign="space-between stretch"
  appDnd
  (fileDropped)="onFileDropped($event, this.filesType == 'videos' ? 'videos' : 'images')"
>
  <div class="content" fxFlex fxLayout="row">
    <input hidden id="fileInput" type="file" #filePicker (change)="onFilePicked(this.filesType == 'videos' ? 'videos' : 'images')" />

    <mat-grid-list [cols]="this.nbCols" rowHeight="200px" [gutterSize]="'10px'" fxFlex (window:resize)="handleCols($event)">
      <mat-grid-tile [colspan]="this.nbCols" *ngIf="this.newFiles.length > 0" class="nouveauxMedias">
        <!-- AFFICHAGE DES NOUVEAUX FICHIERS UPLOADES -->
        <mat-grid-list [cols]="this.nbCols" rowHeight="200px" [gutterSize]="'10px'" fxFlex (window:resize)="handleCols($event)">
          <mat-grid-tile> Nouveaux Médias </mat-grid-tile>
          <mat-grid-tile
            *ngFor="let file of newFiles"
            class="file-or-folder"
            [ngClass]="selectedItems.indexOf(file) > -1 ? 'selected' : 'unselected'"
          >
            <span></span>
            <div fxLayout="column" fxLayoutAlign="space-between center" (click)="selectFile(file)" (contextmenu)="openMenu()">
              <img *ngIf="this.filesType == 'images'" src="{{ serverUrl }}/synchro/{{ this.gestCode }}/{{ file }}" class="vignette" />
              <video
                *ngIf="this.filesType == 'videos'"
                src="{{ serverUrl }}/synchro/{{ this.gestCode }}/VIDEO/{{ file }}"
                class="vignette"
                (mouseover)="playVideo($event)"
                (mouseout)="stopVideo($event)"
                muted
                webkit-playsinline
                playsinline
              ></video>

              <span>{{ file }}</span>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-grid-tile>
      <mat-grid-tile
        *ngFor="let file of files"
        class="file-or-folder"
        [ngClass]="selectedItems.indexOf(file) > -1 ? 'selected' : 'unselected'"
      >
        <span></span>
        <div fxLayout="column" fxLayoutAlign="space-between center" (click)="selectFile(file)" (contextmenu)="openMenu()">
          <img *ngIf="this.filesType == 'images'" src="{{ serverUrl }}/synchro/{{ this.gestCode }}/{{ file }}" class="vignette" />
          <video
            *ngIf="this.filesType == 'videos'"
            src="{{ serverUrl }}/synchro/{{ this.gestCode }}/VIDEO/{{ file }}"
            class="vignette"
            (mouseover)="playVideo($event)"
            (mouseout)="stopVideo($event)"
            muted
            webkit-playsinline
            playsinline
          ></video>

          <span>{{ file }}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
