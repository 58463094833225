import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { AuthService } from './auth.service';
import { User } from 'src/app/models/user.model';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appHasRole]',
})
export class HasRoleDirective implements OnInit, OnDestroy {
  private currentUser: User;
  private roles = [];
  private isHidden = true;
  private userSub: Subscription;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.currentUser = this.authService.getAuthenticatedUser();
    this.updateView();

    this.userSub = this.authService.getAuthStatusListener().subscribe((user) => {
      this.currentUser = user;
      this.updateView();
    });
  }

  @Input()
  set appHasRole(val) {
    this.roles = val;
    this.updateView();
  }

  private updateView() {
    console.log('HasRoleDirective.updateView = ', this.currentUser);
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.changeDetector.markForCheck();
        this.isHidden = false;
      }
    } else {
      this.changeDetector.markForCheck();
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (!(this.roles && this.roles.length > 0)) {
      hasPermission = true;
    } else if (this.currentUser && this.currentUser.role) {
      hasPermission = this.roles.some((role) => role.toUpperCase() === this.currentUser.role);
    }

    return hasPermission;
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }
}
