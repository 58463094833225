import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SynchroComponent } from '../../../modules/synchro/synchro.component';
import { environment } from '../../../../environments/environment';
import { FileManagerService } from './file-manager.service';
import { AuthService } from '../../security/auth.service';
import { User } from '../../../models/user.model';
import { SynchroService } from '../../../modules/synchro/synchro.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
})
export class FileManagerComponent implements OnInit {
  @ViewChild('filePicker')
  filePicker;
  gestCode: string;
  title: string;
  files: string[];
  serverUrl = environment.serverUrl;
  currentUser: User;
  selectedItems = [];
  nbCols: number;
  filesType: string;
  newFiles: string[] = [];

  constructor(private synchroService: SynchroService, private authService: AuthService, private fileManagerService: FileManagerService) {
    this.currentUser = this.authService.getAuthenticatedUser();
    this.gestCode = this.currentUser.contact.gestionnaire.code;
  }

  ngOnInit() {
    this.filesType = this.fileManagerService.filesType;
    console.log('filestype:', this.filesType);
    console.log('filemanagerservice:', this.fileManagerService.filesType);
    this.nbCols = window.innerWidth <= 600 ? 1 : 6;

    // récupérer la liste des fichiers du gestionnaire
    this.fileManagerService.getMediasByGestionnaire(this.gestCode, this.fileManagerService.filesType).subscribe((files) => {
      this.files = files;
      console.log('this.files:', this.files);
    });
  }

  //Gestion du drag and drop pour l'upload d'une fichier
  onFileDropped(event, folderName) {
    let type = '';
    console.log('event:', event[0]);
    console.log('folder:', folderName);
    const file = event[0];

    //Upload du fichier dans le bon dossier avec le bon type
    if (folderName.indexOf('video') >= 0) {
      console.log("c'est une vidéo");
      type = 'VIDEO';
    } else {
      console.log("c'est une image");
    }

    this.fileManagerService.uploadNewGestionnaireFile(file, type).subscribe((responseData) => {
      console.log('response:', responseData);
      this.newFiles.push(responseData.fileName);
      console.log('newfiles:', this.newFiles);
    });
  }

  // ajout d'un nouveau fichier
  onFilePicked(folderName) {
    let type = '';
    console.log(this.filePicker);
    const file = this.filePicker.nativeElement.files[0];

    //Upload du fichier dans le bon dossier avec le bon type
    if (folderName.indexOf('video') >= 0) {
      console.log("c'est une vidéo");
      type = 'VIDEO';
    } else {
      console.log("c'est une image");
    }

    //Upload du fichier dans le bon dossier
    this.fileManagerService.uploadNewGestionnaireFile(file, type).subscribe((responseData) => {
      console.log('response:', responseData);
      this.newFiles.push(responseData.fileName);
      console.log('newfiles length:', this.newFiles.length);
    });
  }

  openMenu() {}

  // Lancer le player video sur le survol de la vignette
  playVideo(event) {
    let videoElement = event.srcElement;
    videoElement.play();
  }
  stopVideo(event) {
    let videoElement = event.srcElement;
    videoElement.pause();
  }

  // Ajouter un fichier à la liste des fichiers sélectionné
  selectFile(filePath) {
    const indexOfFile = this.fileManagerService.chosenFiles.indexOf(filePath);

    // Si le fichier est dans la liste, on le supprime
    if (indexOfFile != -1) {
      this.fileManagerService.chosenFiles.forEach((value, index) => {
        if (value == this.fileManagerService.chosenFiles[indexOfFile]) this.fileManagerService.chosenFiles.splice(index, 1);
      });
    } else {
      //Si le fichier n'est pas dans la liste des fichiers sélectionnés
      // Si la selection multiple n'est pas possible, on vide la liste de fichiers sélecitonnés
      if (this.fileManagerService.multiFiles == false) this.fileManagerService.chosenFiles = [];
      // Si le fichier n'est pas sélectionné, on l'ajouter à la liste
      this.fileManagerService.chosenFiles.push(filePath);
    }
    this.selectedItems = this.fileManagerService.chosenFiles;
    console.log(this.fileManagerService.chosenFiles);
  }

  handleCols(event) {
    if (event.target.innerWidth <= 400) {
      this.nbCols = 1;
    } else {
      if (event.target.innerWidth <= 500) {
        this.nbCols = 2;
      } else {
        if (event.target.innerWidth <= 600) {
          this.nbCols = 3;
        } else {
          if (event.target.innerWidth <= 700) {
            this.nbCols = 4;
          } else {
            if (event.target.innerWidth <= 800) {
              this.nbCols = 5;
            } else {
              this.nbCols = 6;
            }
          }
        }
      }
    }
  }
}
