<div class="slider">
  <div class="slide">
    <img class="slide" [src]="getCurrentSlideUrl()" mat-card-image />
    <!-- <div>
      <div (click)="goToPrevious()" class="leftArrow">❰</div>
      <div (click)="goToNext()" class="rightArrow">❱</div>
    </div> -->
    <div class="dotsContainer">
      <div *ngFor="let slide of slides; let slideIndex = index" class="dot" (click)="goToSlide(slideIndex)">●</div>
    </div>
  </div>
</div>
