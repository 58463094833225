<button mat-button fxFlexAlign="center" [color]="color" [matTooltip]="tooltip" [disabled]="disabled">
  <mat-icon *ngIf="icon" inline>{{ icon }}</mat-icon>
  <span> {{ label }}</span>
</button>

<!-- // ancienne version avec disparition des labels sur petits ecran -->
<!-- <button
  mat-button
  [class.mat-icon-button]="!label || (isHandset$ | async)"
  fxFlexAlign="center"
  [color]="color"
  [matTooltip]="tooltip"
  [disabled]="disabled"
>
  <mat-icon *ngIf="icon" inline>{{ icon }}</mat-icon>

   <span *ngIf="!(isHandset$ | async)"> {{ label }}</span>
</button> -->
