import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
const BACKEND_URL = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  chosenFiles: string[] = [];
  multiFiles: boolean = false;
  filesType: string;

  constructor(private http: HttpClient) {}

  //Récupérer le fichiers d'un gestionnaire
  getMediasByGestionnaire(gestCode: string, fileType?: string) {
    const url = BACKEND_URL + '/synchro/listfiles/' + fileType;
    console.log('fichiers:', url);
    return this.http.get<[]>(url);
  }

  // UPLOADER UN NOUVEAU FICHIER SUR LE SERVEUR FTP DU GESTIONNAIRE
  uploadNewGestionnaireFile(file: File, type?: string) {
    let url: string;
    const postData = new FormData();
    postData.append('file', file);

    console.log('postdata:', postData);
    if (type == 'VIDEO') {
      url = BACKEND_URL + '/synchro/VIDEO/';
    } else {
      url = BACKEND_URL + '/synchro/';
    }

    console.log('url:', url);
    return this.http.post<{ message: string; fileName: string }>(url, postData);
  }

  // Ajouter ou supprimer un fichier d'une ou plusieurs machine pour une catégorie
  updateFileForCategory(category: string, machines, files: string[]) {
    const encodedFiles = files.sort().join('#K#');

    const data = {
      category,
      machines,
      files: encodedFiles,
    };
    const url = BACKEND_URL + '/synchro/update/';
    console.log('data:', data);
    return this.http.post<{ success: string; rejects: [{ reject: File; reason: string }] }>(url, data);
  }
}
