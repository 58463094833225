import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { StaticReflector } from '@angular/compiler';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const isAuth = this.authService.isAuthenticated();
    console.log('Guard : ', isAuth);
    const url = state.url;
    console.log(url);
    if (!isAuth) {
      this.router.navigate(['/login/'], { queryParams: { route: url } });
    }
    return isAuth;
  }
}
