import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorSnackComponent implements OnInit {
  message = 'An unknown error occured';

  constructor(
    public snackBarRef: MatSnackBarRef<ErrorSnackComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { title: string; message: string; error: Error },
  ) {}

  ngOnInit() {}
}
